<template>
  <div>
    <footer class="page-footer">
      <div class="page-footer__inner">
        <ul class="page-footer__list">
          <li v-for="item in menu" :key="item.id" @click="setActive(item)" :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']">
            <router-link
              v-if="item.available != 0 && !item.countryExclusion.includes(currentCountry)"
              :to="{
                name: 'ProductType',
                params: { id: item.type, name: helpers.portal_name },
              }"
              >{{ $t('menu.id'+item.id) }}</router-link
            >
          </li>
        </ul>

        <ul class="page-footer__list page-footer__list--utility">
          <li :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']">
            <router-link to="/contact">{{ $t("contactus") }}</router-link>
          </li>
          <li :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']">
            <router-link to="/faq">{{ $t("faq") }}</router-link>
          </li>
          <li :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']">
            <router-link to="/terms">{{ $t("terms") }}</router-link>
          </li>
          <li :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']">
            <router-link to="/privacy">{{ $t("privacy") }}</router-link>
          </li>
          <li :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']">
            <router-link to="/cookies-policy">{{ $t("cookiespolicy") }}</router-link>
          </li>
          <li v-if="currentCountry=='AT' || (currentCountry=='CH' && helpers.portal_name == 'Appiland')">
            <router-link to="/impressum">{{ $t("impressum") }}</router-link>
          </li>
          <li :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']">
            <router-link to="/unsubscription">{{
              $t("unsubscription")
            }}</router-link>
          </li>
        </ul>

        <p class="copyright">Copyright @ 2023</p>
      </div>
    </footer>
  </div>
</template>

<script>
import { helpers } from '../helper/portal_config';
import EventBus from '../helper/event_bus';

export default {
  name: 'Footer',
  props: ['color'],
  data() {
    return {
      helpers: '',
      menu: [],
      currentLanguage: "",
      currentCountry: ""
    };
  },
  methods: {
    setActive(item) {
      // start a bus to menu
      EventBus.$emit('SET_MENU_ACTIVE', item);
    },
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
      console.log('setCurrentCountry='+this.currentCountry);
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
  },
  created() {
    this.helpers = helpers;
    this.menu = helpers.menu;
    
    this.setCurrentLanguage();
    this.setCurrentCountry();
  },
};
</script>

<template>
  <div>
    <header class="page-header">
      <div class="page-header__left">
        <button class="menu-btn" @click="toggleSideMenu">
          <span class="sr-only">Menu</span>
        </button>
        <a href="/" class="page-header__logo">
          <img src="../assets/images/portal-logo.png" alt="logo" />
        </a>

        <nav class="page-header__menu">
          <ul>
            <li
              v-for="item in menu"
              :key="item.id"
              :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '', item.isActive === true ? 'is-active':'']" 
                @click="setActive(item)"
            >
            <router-link
                v-if="item.available != 0 && !item.countryExclusion.includes(currentCountry)"
                :to="{
                    name: 'ProductType',
                    params: {
                        name: helpers.portal_name,
                        id: item.type
                    },
                }">
                <div class="menu-link">
                  {{ $t('menu.id'+item.id) }}
                </div>
            </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <div class="page-header__actions">
        <Search />
        <button class="logout-btn" @click="logout" v-if="isLoggedIn" title="logout">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.969"
            height="17.242"
            viewBox="0 0 16.969 17.242"
          >
            <path
              d="M21.41,1.851a8.483,8.483,0,1,1-9.86,0,.824.824,0,0,1,1.2.263l.541.961a.821.821,0,0,1-.226,1.061,5.747,5.747,0,1,0,6.839,0,.816.816,0,0,1-.222-1.057l.541-.961a.82.82,0,0,1,1.191-.267ZM17.853,9.032V.821A.819.819,0,0,0,17.031,0H15.937a.819.819,0,0,0-.821.821V9.032a.819.819,0,0,0,.821.821h1.095A.819.819,0,0,0,17.853,9.032Z"
              transform="translate(-8)"
            />
          </svg>
        </button>


        <button v-b-modal.singInModal class="signin-btn" v-if="!isLoggedIn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.015"
            height="15.325"
            viewBox="0 0 20.015 15.325"
          >
            <path
              d="M16.262,79.011H12.978a.47.47,0,0,1-.469-.469V76.978a.47.47,0,0,1,.469-.469h3.284a1.25,1.25,0,0,0,1.251-1.251V67.753A1.25,1.25,0,0,0,16.262,66.5H12.978a.47.47,0,0,1-.469-.469V64.469A.47.47,0,0,1,12.978,64h3.284a3.754,3.754,0,0,1,3.753,3.753v7.506A3.754,3.754,0,0,1,16.262,79.011Zm-1.837-7.857L7.857,64.586a.94.94,0,0,0-1.6.665V69H.938A.936.936,0,0,0,0,69.942v3.753a.936.936,0,0,0,.938.938H6.255v3.753a.94.94,0,0,0,1.6.665l6.567-6.567A.946.946,0,0,0,14.425,71.154Z"
              transform="translate(0 -64)"
              fill="#0a0a0a"
            />
          </svg>
          <span :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']">{{ $t('signin') }}</span>
        </button>
      </div>
    </header>

    <SideMenu :open="isOpen" @closed="closeSideMenu" />
    <SignInModal />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { helpers } from '../helper/portal_config';
import EventBus from '../helper/event_bus';

import SideMenu from './SideMenu';
import SignInModal from './SignInModal';
import Search from './Search';

export default {
  name: 'Header',
  data() {
    return {
      helpers: '',
      isOpen: false,
      menu: [],
      currentLanguage: "",
      currentCountry: ""
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'globals/isLoggedIn',
    }),
  },
  methods: {
    ...mapActions({
      logout: 'globals/logout',
    }),
    currentActiveMenu(item) {
      return true;
    },
    toggleSideMenu() {
      this.isOpen = !this.isOpen;
    },
    closeSideMenu() {
      this.isOpen = false;
    },
    logout() {
      this.$store.dispatch('globals/logout').then(() => {
        this.$router.push('/').catch(() => {});
      });
    },
    setActive(item) {
      this.menu = this.menu.map((menuItem) => {
        if (menuItem.id === item.id) {
          menuItem.isActive = true;
        } else {
          menuItem.isActive = false;
        }
        return menuItem;
      });
    },
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
  },
  components: {
    SideMenu,
    SignInModal,
    Search
  },
  created() {
    this.helpers = helpers;
    this.menu = helpers.menu;
    
    this.setCurrentLanguage();
    this.setCurrentCountry();
  },
  mounted() {
    EventBus.$on('SET_MENU_ACTIVE', (item) => {
      this.setActive(item);
    });
  },
};
</script>

<style scoped>
    .page-header__actions {
        display: flex;
        align-items: center;
    }
    .redClass:before {
        color: #f6212b;
    }
    .yellowClass:before {
        color: #ffdd3d;
    }
</style>

import { render, staticRenderFns } from "./ProductDetailsVideo.vue?vue&type=template&id=2871bd70&scoped=true&"
import script from "./ProductDetailsVideo.vue?vue&type=script&lang=js&"
export * from "./ProductDetailsVideo.vue?vue&type=script&lang=js&"
import style0 from "./ProductDetailsVideo.vue?vue&type=style&index=0&id=2871bd70&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2871bd70",
  null
  
)

export default component.exports
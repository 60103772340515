import { render, staticRenderFns } from "./SideMenu.vue?vue&type=template&id=6ee74a5d&%3Aclass=%5Bthis.currentLanguage%20%3D%3D%20'AR'%20%3F%20'rtlText'%3A%20''%5D&"
import script from "./SideMenu.vue?vue&type=script&lang=js&"
export * from "./SideMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports